<template>
  <div class="payewm">
    <!-- 支付二维码 -->
    <div class="payewmLogo">
      <template v-if="payType=='single_alipay'">
        <span class="iconfont iconzhifubao" style="color:#108ee9"></span>
        支付宝支付
      </template>
      <template v-else>
        <span class="iconfont iconweixin1" style="color:#45AD33"></span>
        微信支付
      </template>
    </div>
    <div class="pay-money">
      <span>￥{{payMoney}}元</span>
    </div>
    <div class="pay-hint">
      <span>过期后请勿支付，不自动到账</span>
    </div>
    <div class="pay-ewm">
      <img :src="imgUrl" alt />
      <div class="refresh" v-show="show" @click="refreshQrcode">
        <span class="iconfont iconshuaxin"></span>
      </div>
    </div>
    <div class="hint-text">
      <img src="../../../public/static/images/vip/zhiwenchangan.png" alt />
      <span>长按“识别图中二维码”进行支付</span>
    </div>
    <div class="pay-ordersn">订单：{{ordersn}}</div>

    <div v-if="!show" class="RefreshCountdown">
      请于
      <count-down :time="time" format=" mm 分 ss 秒" ref="countDown" @finish="finish" />内支付
    </div>
    <div v-else class="RefreshCountdown">点击刷新二维码</div>
  </div>
</template>

<script>
import CreditShop from "@/api/creditshop";
import { CountDown } from "vant";
export default {
  components: {
    CountDown
  },
  data() {
    return {
      imgUrl: "",
      ordersn: "",
      payMoney: "",
      time: 1000 * 60 * 3,
      show: false,
      payType: ""
    };
  },
  created() {
    this.imgUrl = this.$route.query.imgUrl;
    this.ordersn = this.$route.query.ordersn;
    this.payMoney = this.$route.query.money;
    this.payType = this.$route.query.payType;
  },
  methods: {
    finish() {
      this.show = true;
    },

    // 刷新二维码
    async refreshQrcode() {
      try {
        const res = await CreditShop.refreshQrcode(this.ordersn, this.payType);
        console.log(res);
        this.imgUrl = res.data.qrcode;
        this.ordersn = res.data.ordersn;
        this.show = false;
         this.$refs.countDown.reset();
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.payewm {
  width: 100%;
  height: 100vh;
  padding-top: 100px;
  text-align: center;

  .payewmLogo {
    text-align: center;
    font-size: 20px;

    span {
      font-size: 24px;
    }
  }

  .pay-money {
    margin: 5px 0;
    font-size: 30px;
    font-weight: bold;
    color: rgba(234, 36, 0, 1);
    line-height: 42px;
    letter-spacing: 1px;
  }

  .pay-hint {
    font-size: 14px;
    font-weight: 500;
    color: rgba(84, 116, 236, 1);
    line-height: 20px;
  }

  .pay-ewm {
    padding: 10px;
    width: 208px;
    height: 208px;
    margin: 0 auto;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.09);
    border-radius: 5px;
    border: 1px solid rgba(219, 219, 219, 1);
    margin-top: 15px;
    position: relative;

    img {
      width: 100%;
      height: 100%;
    }

    .refresh {
      width: 208px;
      height: 208px;
      position: absolute;
      top: 0;
      left: 0;
      background: #000000ad;
      color: #fff;
      line-height: 208px;
      text-align: center;

      span {
        font-size: 30px;
      }
    }
  }

  .hint-text {
    margin-top: 15px;
    font-size: 16px;
    font-weight: 500;
    color: rgba(234, 36, 0, 1);
    line-height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 26px;
      margin-right: 15px;
    }
  }

  .pay-ordersn {
    margin-top: 10px;
    font-size: 16px;
    font-weight: 500;
    color: rgba(51, 51, 51, 1);
    line-height: 20px;
  }

  .RefreshCountdown {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    font-size: 15px;
  }
}
</style>